<template>
  <b-modal
    :id="id"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    hide-header
    centered
    @shown="countDownTimerOtp()"
  >

    <b-row class="justify-content-end">
      <b-img
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        class="cursor-pointer m-50"
        @click="resetCondition(), $bvModal.hide(id)"
      />
    </b-row>

    <b-row class="mb-1 justify-content-center">
      <h4 class="text-black text-center">
        <strong>
          {{ title }}
        </strong>
      </h4>
    </b-row>

    <b-row class="mb-1 justify-content-center">
      <b-img
        v-if="requestType === 'email' || requestType === 'pin'"
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/sms.svg"
        style="width: 40px;"
      />
      <b-img
        v-if="requestType === 'whatsapp'"
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/Whatsapp.svg"
        style="width: 40px;"
      />
      <b-img
        v-if="requestType === 'sms'"
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/call.svg"
        style="width: 40px;"
      />
    </b-row>

    <b-row class="mb-1 justify-content-center">
      <h5 class="text-black text-center">
        <strong>
          Masukkan Kode Verifikasi
        </strong>
      </h5>
    </b-row>

    <b-row class="mb-2 justify-content-center mx-2">
      <span class="text-center">
        Masukkan kode verifikasi (OTP) yang telah dikirim melalui {{ getReqType() }}
      </span>
    </b-row>

    <b-row class="justify-content-center mb-50">
      <div style="display: flex; flex-direction: row;">
        <PincodeInput
          v-model="otp"
          placeholder=""
          :length="6"
          :disabled="disabledInputOTP || maxChanceOTP"
        />
      </div>
    </b-row>

    <b-row
      v-if="messageErrorOTP !== ''"
      class="justify-content-center text-center my-1"
    >
      <b-col md="10">
        <small class="text-danger">
          {{ messageErrorOTP }}
        </small>
      </b-col>
    </b-row>

    <b-row
      class="mb-1 justify-content-center"
    >
      <b-col
        v-if="purpose === 'phone' || purpose === 'rekening'"
        class="mt-1"
      >
        <b-button
          variant="outline-primary"
          block
          class="text-center text-primary"
          @click="backToSelectMethods"
        >
          Ganti metode lain
        </b-button>
      </b-col>

      <b-col
        cols="6"
        class="mt-1"
      >
        <b-button
          variant="primary"
          block
          :disabled="otp === '' || otp.length < 6 || maxChanceOTP"
          @click="handleVerifyOtp"
        >
          {{ labelConfirmButton }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-50 justify-content-center">
      <small
        v-if="countOtp !== 0"
        class="text-center"
      >
        Tunggu dalam {{ countOtp }} {{ lastChanceOTP ? 'Jam' : 'detik' }} untuk kirim ulang
      </small>
      <small v-if="countOtp === 0">
        Tidak menerima kode?
        <b-button
          variant="flat-primary"
          class="btn-icon"
          size="sm"
          @click="requestOTP"
        >
          <b-spinner
            v-if="loading"
            small
          /> Kirim ulang
        </b-button>
      </small>
    </b-row>
  </b-modal>
</template>
<script>
import PincodeInput from 'vue-pincode-input'
import { removeLocalStorageLogout } from '@/auth/utils'
import secureLs from '@/libs/secureLs'

export default {
  components: {
    PincodeInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    partnerId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
      required: false,
    },
    phoneNumber: {
      type: String,
      default: '',
      required: false,
    },
    requestType: {
      type: String,
      default: '',
      required: false,
    },
    purpose: {
      type: String,
      default: '',
      required: false,
    },
    bankName: {
      type: String,
      default: '',
      required: false,
    },
    accountNo: {
      type: String,
      default: '',
      required: false,
    },
    accountName: {
      type: String,
      default: '',
      required: false,
    },
    labelConfirmButton: {
      type: String,
      default: 'Ganti Email',
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      otp: '',
      countOtp: 0,
      messageErrorOTP: '',
      disabledInputOTP: false,
      maxChanceOTP: false,
      lastChanceOTP: false,
      params: '',
      removeLocalStorageLogout,
    }
  },
  methods: {
    resetCondition() {
      this.otp = ''
      this.countOtp = 0
      this.messageErrorOTP = ''
      this.disabledInputOTP = false
    },
    countDownTimerOtp() {
      if (this.requestType === 'whatsapp' || this.requestType === 'sms') {
        this.params = 'phone'
      } else if (this.requestType === 'email') {
        this.params = 'email'
      } else {
        this.params = 'email'
      }
      this.lastChanceOTP = false
      const securityChange = secureLs.getItem(`securityChange${this.purpose}`)
      if (securityChange !== undefined) {
        this.maxChanceOTP = securityChange.attempt_left === 0
        const now = new Date()
        const targetDate = new Date(securityChange.securityChange.next_request_at)
        const countOtp = Math.floor((targetDate - now) / 1000) < 0 ? 0 : Math.floor((targetDate - now) / 1000)
        if (countOtp < 500) {
          this.countOtp = countOtp
          setTimeout(() => {
            this.countOtp -= 1
            this.countDownTimerOtp()
          }, 1000)
        } else if (countOtp < 80000) {
          this.countOtp = 1
          this.lastChanceOTP = true
          this.maxChanceOTP = true
        } else {
          this.countOtp = Math.floor(countOtp / 3600)
          this.lastChanceOTP = true
        }
      }
    },
    getReqType() {
      if (this.requestType === 'email' || this.requestType === 'pin') {
        return `email ke ${this.token === '' ? this.censorEmail(this.email) : this.email}`
      } if (this.requestType === 'sms') {
        return `SMS ke ${this.token === '' || this.purpose === 'rekening' ? this.censorPhone(this.phoneNumber) : this.phoneNumber}`
      } if (this.requestType === 'whatsapp') {
        return `WhatsApp ke ${this.token === '' || this.purpose === 'rekening' ? this.censorPhone(this.phoneNumber) : this.phoneNumber}`
      }
      return ''
    },
    backToSelectMethods() {
      this.resetCondition()
      this.$bvModal.hide(this.id)
      this.$emit('on-cancel-button')
    },
    censorEmail(value) {
      if (value !== '') {
        const email = value.split('@')
        const realEmail = email[0].slice(0, 3) + '*'.repeat(email[0].length)
        const domainEmail = email[1].slice(0, 1) + '*'.repeat(email[1].length - 3) + email[1].slice(-3)
        return `${realEmail}@${domainEmail}`
      }
      return ''
    },
    censorPhone(phone) {
      if (phone !== '') {
        return '*'.repeat(phone.length) + phone.slice(-3)
      }
      return ''
    },
    requestOTP() {
      this.resetCondition()
      let body = {}
      if (this.token !== '') {
        body = {
          token: this.token,
          purpose: this.purpose,
        }
        if (this.purpose === 'phone') {
          Object.assign(body, {
            phone: this.phoneNumber,
            type_otp: this.requestType,
          })
        } else if (this.purpose === 'rekening') {
          Object.assign(body, {
            type_otp: this.requestType,
          })
        } else {
          Object.assign(body, { email: this.email })
        }
      } else {
        body = {
          purpose: this.purpose,
        }
        if (this.purpose === 'phone' || this.purpose === 'rekening') Object.assign(body, { type_otp: this.requestType })
      }

      this.$http_new.post(`/auth/api/v1/otp/request-otp/${this.params}`, body)
        .then(response => {
          const combinedData = {
            id: this.partnerId,
            email: this.email,
            phone: this.phoneNumber,
            securityChange: response.data.data,
          }
          secureLs.setItem(`securityChange${this.purpose}`, JSON.stringify(combinedData))
          this.countDownTimerOtp()
          this.loading = false
        }).catch(err => {
          let message = ''
          if (err.response.data.data === 'reached limit request otp') {
            message = 'Akunmu telah mencapai batas permintaan OTP, silahkan coba kembali 24 jam dari permintaan OTP terakhir'
          } else {
            message = 'Gagal kirim permintaan OTP, silahkan coba lagi'
          }
          this.loading = false
          this.$toast_error({ message: `${message}` })
        })
    },
    handleVerifyOtp() {
      this.messageErrorOTP = ''
      this.disabledInputOTP = false
      const securityChange = secureLs.getItem(`securityChange${this.purpose}`)
      const token = btoa(`${securityChange.securityChange.token}%${this.purpose}`)
      this.$http_new.post('/auth/api/v1/otp/verify-otp', {
        otp: this.otp,
        token,
      }).then(response => {
        if (response.data.meta.code === 200) {
          if (this.token !== '') {
            this.getSecured()
          } else {
            this.$emit('on-confirm-button', securityChange)
            this.resetCondition()
            this.$bvModal.hide(this.id)
          }
          if (this.title === 'Lupa PIN') {
            secureLs.removeItem(`securityChange${this.purpose}`)
          }
        }
      }).catch(err => {
        const updateAttemptLeft = {
          ...securityChange,
          attempt_left: err.response.data.data !== null ? err.response.data.data.attempt_left : 0,
        }

        secureLs.setItem(`securityChange${this.purpose}`, JSON.stringify(updateAttemptLeft))
        if (this.lastChanceOTP && err.response.data.data.attempt_left !== 0) {
          this.messageErrorOTP = `Kode OTP salah. Tersisa ${err.response.data.data.attempt_left} percobaan lagi sebelum akun logout otomatis.`
        } else if (this.lastChanceOTP && err.response.data.data.attempt_left === 0) {
          if (this.id === 'confirm-otp-new-email' || this.id === 'confirm-otp-new-phone') {
            secureLs.removeItem(`securityChange${this.purpose}`)
            this.$bvModal.hide(this.id)
            this.resetCondition()
          } else {
            secureLs.removeItem(`securityChange${this.purpose}`)
            this.removeLocalStorageLogout()
            window.location.replace('/')
          }
        } else if (err.response.data.meta.message === 'otp not valid' && err.response.data.data.attempt_left !== 0) {
          this.messageErrorOTP = `Kode OTP salah. Harap cek OTP pada ${this.requestType === 'pin' ? 'email' : this.requestType}, lalu coba lagi.`
        } else if (err.response.data.meta.message === 'otp not found or already expired, please request a new one' || err.response.data.data.attempt_left === 0) {
          this.messageErrorOTP = 'Kesempatan Percobaan Input OTP Habis, Silahkan Kirim Ulang OTP.'
          this.disabledInputOTP = true
          this.dataPin = ''
        }
      })
    },
    getSecured() {
      const securityChange = secureLs.getItem(`securityChange${this.purpose}`)
      const token = btoa(`${securityChange.securityChange.token}%${this.purpose}`)
      let params
      const body = {
        token,
      }
      if (this.purpose === 'email') {
        Object.assign(body, { new_email: this.email })
        params = 'update-email'
      } else if (this.purpose === 'rekening') {
        Object.assign(body, {
          bank_name: this.bankName,
          account_no: this.accountNo,
          account_name: this.accountName,
        })
        params = 'add-rekening'
      } else {
        Object.assign(body, { new_phone: this.phoneNumber })
        params = 'update-phone'
      }
      this.$http_new.post(`/auth/api/v1/otp/secured/user/${params}`, body)
        .then(response => {
          if (response.status === 200) {
            this.$emit('on-confirm-button')
            secureLs.removeItem(`securityChange${this.purpose}`)
            this.resetCondition()
            this.$bvModal.hide(this.id)
          }
        })
        .catch(err => {
          this.messageErrorOTP = `Gagal melakukan perubahan ${this.purpose === 'email' ? 'Email' : 'Nomor HP'}, silahkan coba kembali`
        })
    },
  },
}
</script>
