<template>
  <b-modal
    :id="id"
    hide-footer
    hide-header
    centered
    @shown="countDownTimerOtp()"
  >
    <b-row class="justify-content-end">
      <b-img
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        class="cursor-pointer m-50"
        @click="resetCondition(), $bvModal.hide(id)"
      />
    </b-row>
    <b-row class="justify-content-center mb-1">
      <h3 class="text-black">
        <strong>
          {{ title }}
        </strong>
      </h3>
    </b-row>
    <b-row class="justify-content-center mb-1">
      <h4 class="text-black">
        <strong>
          Pilih Metode Verifikasi
        </strong>
      </h4>
    </b-row>
    <b-row class="justify-content-center mb-1 mx-5">
      <span class="text-center">
        Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
      </span>
    </b-row>
    <b-row
      v-if="countOtp !== 0 && phoneNumber !== ''"
      class="justify-content-center mb-1"
    >
      <b-col
        cols="11"
        class="bg-[#F8ECC8] text-center p-[5px] rounded"
      >
        <span class="text-[#CC9212]">
          Ganti metode dalam <b>({{ countOtp }} {{ lastChanceOTP ? 'Jam' : 'detik' }})</b>
        </span>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mb-1">
      <b-col
        v-if="phoneNumber === ''"
        cols="11"
        :class="loadingEmail ? 'bg-[#FCD4BE]' : ''"
        @click="setOptions('email')"
      >
        <b-row class="py-1 wrapper-box-email hover:bg-[#FFECE9]">
          <b-col
            cols="2"
            class="p-0 d-flex justify-content-center align-items-center"
          >
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/sms.svg"
              style="width: 40px;"
            />
          </b-col>
          <b-col
            cols="10"
            style="position: relative;"
          >
            <div class="d-flex align-items-center">
              <div>
                <h5>
                  <strong>
                    Email ke
                  </strong>
                </h5>
                <span>
                  {{ censorEmail(email) }}
                </span>
              </div>
              <div
                v-if="loadingEmail"
                style="position: absolute; right: 0;"
              >
                <lottie-animation
                  path="animation/LoadingProcess.json"
                  :width="80"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="email === ''"
        cols="11"
        :class="loadingWa ? 'bg-[#FCD4BE]' : ''"
        class="my-1"
        @click="setOptions('whatsapp')"
      >
        <b-row
          class="py-1 wrapper-box-email"
          :class="countOtp === 0 && isWhatsapp === 'valid' ? 'hover:bg-[#FFECE9]' : 'bg-[#E2E2E2] hover:bg-none'"
        >
          <b-col
            cols="2"
            class="p-0 d-flex justify-content-center align-items-center"
          >
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/Whatsapp.svg"
              style="width: 40px;"
            />
          </b-col>
          <b-col
            cols="10"
            style="position: relative;"
          >
            <div class="d-flex align-items-center">
              <div>
                <h5>
                  <strong>
                    Whatsapp kode OTP ke
                  </strong>
                </h5>
                <span>
                  {{ censorPhone(phoneNumber) }}
                </span>
              </div>
              <div
                v-if="loadingWa"
                style="position: absolute; right: 0;"
              >
                <lottie-animation
                  path="animation/LoadingProcess.json"
                  :width="80"
                />
              </div>
            </div>
            <span
              v-if="isWhatsapp === 'valid'"
              style="color: #34A770"
            >
              Nomor ini terhubung ke Whatsapp
            </span>
            <span
              v-if="isWhatsapp === 'invalid'"
              style="color: #E31A1A"
            >
              Nomor ini tidak terhubung ke Whatsapp
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="email === ''"
        cols="11"
        :class="loadingSms ? 'bg-[#FCD4BE]' : ''"
        @click="setOptions('sms')"
      >
        <b-row
          class="py-1 wrapper-box-email"
          :class="countOtp === 0 ? 'hover:bg-[#FFECE9]' : 'bg-[#E2E2E2] hover:bg-none'"
        >
          <b-col
            cols="2"
            class="p-0 d-flex justify-content-center align-items-center"
          >
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/call.svg"
              style="width: 40px;"
            />
          </b-col>
          <b-col
            cols="10"
            style="position: relative;"
          >
            <div class="d-flex align-items-center">
              <div>
                <h5>
                  <strong>
                    SMS kode OTP ke
                  </strong>
                </h5>
                <span>
                  {{ censorPhone(phoneNumber) }}
                </span>
              </div>
              <div
                v-if="loadingSms"
                style="position: absolute; right: 0;"
              >
                <lottie-animation
                  path="animation/LoadingProcess.json"
                  :width="80"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import secureLs from '@/libs/secureLs'

export default {
  components: {
    LottieAnimation,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    requestType: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
      required: false,
    },
    phoneNumber: {
      type: String,
      default: '',
      required: false,
    },
    token: {
      type: String,
      default: '',
      required: false,
    },
    purpose: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      loadingEmail: false,
      loadingWa: false,
      loadingSms: false,
      countOtp: 0,
      isWhatsapp: '',
      lastChanceOTP: false,

      typeOtp: '',
      params: '',
    }
  },
  methods: {
    resetCondition() {
      this.loadingEmail = false
      this.loadingWa = false
      this.loadingSms = false
      this.isWhatsapp = ''
      this.$bvModal.hide(this.id)
    },
    countDownTimerOtp() {
      this.lastChanceOTP = false
      if (this.title === 'Lupa PIN') {
        this.params = 'email'
      } else if (this.title === 'Edit Email') {
        this.params = 'email'
      } else {
        this.params = 'phone'
      }

      const securityChange = secureLs.getItem(`securityChange${this.purpose}`)

      if (securityChange !== undefined && securityChange.phone === this.phoneNumber) {
        const now = new Date()
        const targetDate = new Date(securityChange.securityChange.next_request_at)
        const countOtp = Math.floor((targetDate - now) / 1000) < 0 ? 0 : Math.floor((targetDate - now) / 1000)
        if (this.purpose === 'phone') {
          if (this.phoneNumber === securityChange.phone) {
            this.getCountTimer(countOtp)
          }
        } else {
          this.getCountTimer(countOtp)
        }
      }
      if (this.phoneNumber !== '' && this.isWhatsapp === '') {
        this.checkWa()
      }
    },
    getCountTimer(countOtp) {
      if (countOtp < 500) {
        this.countOtp = countOtp
        setTimeout(() => {
          this.countOtp -= 1
          this.countDownTimerOtp()
        }, 1000)
      } else if (countOtp < 3600) {
        this.countOtp = 1
        this.lastChanceOTP = true
      } else {
        this.countOtp = Math.floor(countOtp / 3600)
        this.lastChanceOTP = true
      }
    },
    checkWa() {
      this.$http_komship.post(`/v1/check-wa?phone_no=${this.phoneNumber}`)
        .then(response => {
          this.isWhatsapp = response.data.data
        }).catch(err => {
          if (err.response.data.data === 'invalid') {
            this.isWhatsapp = err.response.data.data
          } else {
            this.$toast_error({ message: `${err}` })
          }
        })
    },
    censorEmail(value) {
      if (value !== '') {
        const email = value.split('@')
        const realEmail = email[0].slice(0, 3) + '*'.repeat(email[0].length)
        const domainEmail = email[1].slice(0, 1) + '*'.repeat(email[1].length - 3) + email[1].slice(-3)
        return `${realEmail}@${domainEmail}`
      }
      return ''
    },
    censorPhone(phone) {
      if (phone !== '') {
        return '*'.repeat(phone.length) + phone.slice(-3)
      }
      return ''
    },
    setOptions(value) {
      this.typeOtp = value
      if (value === 'email') {
        this.loadingEmail = true
        this.selectedEmail()
      } else if (value === 'whatsapp' && this.countOtp === 0 && this.isWhatsapp === 'valid') {
        this.loadingWa = true
        this.requestOTP()
      } else if (value === 'sms' && this.countOtp === 0) {
        this.loadingSms = true
        this.requestOTP()
      } else {
        console.log('not options selected, or countdown not finish')
      }
    },
    selectedEmail() {
      const securityChange = secureLs.getItem(`securityChange${this.purpose}`)
      if (securityChange !== undefined && this.countOtp !== 0 && this.partnerId === securityChange.id && this.email === securityChange.email) {
        this.$emit('on-confirm-button')
        this.resetCondition()
      } else {
        this.requestOTP()
      }
    },
    requestOTP() {
      const body = {
        purpose: this.purpose,
      }
      if (this.purpose === 'phone' || this.purpose === 'rekening') Object.assign(body, { type_otp: this.typeOtp })
      if (this.id === 'change-phone') {
        const securityChange = secureLs.getItem(`securityChange${this.purpose}`)
        const token = btoa(`${securityChange.securityChange.token}%${this.purpose}`)
        Object.assign(body, { token, phone: this.phoneNumber })
      }
      if (this.id === 'req-OTP-new-phone') {
        Object.assign(body, { token: this.token, phone: this.phoneNumber })
      }
      this.$http_new.post(`/auth/api/v1/otp/request-otp/${this.params}`, body)
        .then(response => {
          const combinedData = {
            id: this.partnerId,
            email: this.email,
            phone: this.phoneNumber,
            securityChange: response.data.data,
          }
          secureLs.setItem(`securityChange${this.purpose}`, JSON.stringify(combinedData))
          this.$emit('on-confirm-button', this.typeOtp)
          this.resetCondition()
        }).catch(err => {
          let message = ''
          if (err.response.data.data === 'reached limit request otp') {
            message = 'Akunmu telah mencapai batas permintaan OTP, silahkan coba kembali 24 jam dari permintaan OTP terakhir'
          } else {
            message = 'Gagal kirim permintaan OTP, silahkan coba lagi'
          }
          this.loadingEmail = false
          this.loadingWa = false
          this.loadingSms = false
          this.$toast_error({ message: `${message}` })
        })
    },
  },
}
</script>
<style scoped>
.wrapper-box-email {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
</style>
